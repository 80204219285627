import React, { useEffect, useState } from 'react';
import axios from 'axios';

const ContactMessages = () => {
  const [messages, setMessages] = useState([]);

  useEffect(() => {
    const fetchMessages = async () => {
      try {
        const response = await axios.get('http://localhost:8000/api/contact');
        setMessages(response.data);
      } catch (error) {
        console.error(error);
      }
    };

    fetchMessages();
  }, []);

  return (
    <div className="container displaycontact">
      <h1 className="mt-4 mb-3">Contact Messages</h1>
      {messages.length === 0 ? (
        <div className="alert alert-info">No messages found.</div>
      ) : (
        messages.map((message) => (
          <div className="card mb-3" key={message.id}>
            <div className="card-body">
              <h6 className="card-subtitle mb-2 text-muted">Message No # {message.id}</h6>
              <h5 className="card-title">{message.name}</h5>
              <h6 className="card-subtitle mb-2 text-muted">{message.email}</h6>
              <p className="card-text">{message.message}</p>
            </div>
          </div>
        ))
      )}
    </div>
  );
};

export default ContactMessages;
