import React from 'react'
import Join from './Join'

const Footer = () => {
  return (
    <>
    <footer id="footer">

    <div className="footer-top">
      <div className="container">
        <div className="row">

          <div className="col-lg-3 col-md-6 footer-contact">
            <h3>MultiSolsZOne</h3>
            <p>
              SadiqAbad <br />
              <a href='tel:03149690762'><strong>Phone:</strong> 03149690762<br /></a>
              {/* <a href='mailto:umerarshad260@gmail.com'><strong>Email:</strong> umerarshad260@gmail.com<br /></a> */}
              <Join />
            </p>
          </div>

          <div className="col-lg-3 col-md-6 footer-links">
            <h4>Useful Links</h4>
            <ul>
              <li><i className="bx bx-chevron-right"></i> <a href="/">Home</a></li>
              <li><i className="bx bx-chevron-right"></i> <a href="/about">About us</a></li>
              <li><i className="bx bx-chevron-right"></i> <a href="/services">Services</a></li>
              <li><i className="bx bx-chevron-right"></i> <a href="/portfolio">Portfolio</a></li>
              <li><i className="bx bx-chevron-right"></i> <a href="/contact">Contact</a></li>
            </ul>
          </div>

          <div className="col-lg-3 col-md-6 footer-links">
            <h4>Our Services</h4>
            <ul>
              <li><i className="bx bx-chevron-right"></i> <a href="/">Web Design</a></li>
              <li><i className="bx bx-chevron-right"></i> <a href="/">Web Development</a></li>
              <li><i className="bx bx-chevron-right"></i> <a href="/">Product Management</a></li>
              <li><i className="bx bx-chevron-right"></i> <a href="/">Marketing</a></li>
              <li><i className="bx bx-chevron-right"></i> <a href="/">Graphic Design</a></li>
            </ul>
          </div>

          <div className="col-lg-3 col-md-6 footer-links">
            <h4>Our Social Networks</h4>
            <div className="social-links mt-3">
              <a href="/" className="twitter"><i className="bx bxl-twitter"></i></a>
              <a href="/" className="facebook"><i className="bx bxl-facebook"></i></a>
              <a href="/" className="instagram"><i className="bx bxl-instagram"></i></a>
              <a href="/" className="google-plus"><i className="bx bxl-skype"></i></a>
              <a href="/" className="linkedin"><i className="bx bxl-linkedin"></i></a>
              <a href="https://api.whatsapp.com/send?phone=03149690762" className="linkedin"><i className="bx bxl-whatsapp"></i></a>
            </div>
          </div>

        </div>
      </div>
    </div>
  </footer>
  </>
  )
}

export default Footer
