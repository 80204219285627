import React from "react";

const Portfolio = () => {
  return (
    <>
      <section id="portfolio" className="portfolio">
        <div className="container" data-aos="fade-up">
          <div className="section-title">
            <h2>Portfolio</h2>
          </div> 
            <div className="">
              <img
                src="assets/img/portfolio/portfolio-1.png"
                className="img-fluid"
                alt=""
                style={{
                  width: "100%",
                  height: "auto",
                  backgroundColor: "skyblue",
                }}
              />
              <h3>Ujrat-The Services Providing App</h3>
              <p>
                It joins users and the workers and serves as bridge between
                them, msking life easier for users and providing business to the
                workers, by having more clients, as the wolrd is becoming
                digital, so digital systems are making complex tasks easy, and
                easier. Thus, this is our system doing, making complex tasks
                much easier than no other way, roles of user, workers, admin,
                mostly system is automated no need for admin to continuously sit
                and give time, most of the in fact 100% of the system is
                automated.
              </p>
            </div>
        </div>
      </section>
    </>
  );
};

export default Portfolio;
