import React from "react";

const Services = () => {
  return (
    <>
      <section id="services" className="services section-bg">
        <div className="container" data-aos="fade-up">
          <div className="section-title">
            <h2>Services</h2>
          </div>

          <div className="row">
            <div
              className="col-xl-6 col-md-6 d-flex align-items-stretch mb-3"
              data-aos="zoom-in"
              data-aos-delay="100"
            >
              <div className="icon-box">
                <div className="icon">
                  <i className="bx bxl-dribbble"></i>
                </div>
                <h4>
                  <a href="">Consultancy</a>
                </h4>
                <p>
                  Empowering businesses with cutting-edge IT solutions. Tailored
                  consultancy services for your specific IT needs. Streamlining
                  your operations through innovative technology solutions.
                  Expert guidance to optimize your IT infrastructure.
                </p>
              </div>
            </div>

            <div
              className="col-xl-6 col-md-6 d-flex align-items-stretch mb-3"
              data-aos="zoom-in"
              data-aos-delay="100"
            >
              <div className="icon-box">
                <div className="icon">
                  <i className="bx bxl-dribbble"></i>
                </div>
                <h4>
                  <a href="">Technical Support</a>
                </h4>
                <p>
                  Reliable technical support for all your IT needs. Expert
                  assistance in resolving hardware and software issues. Prompt
                  and efficient troubleshooting for uninterrupted operations.
                  Dedicated support team available 24/7 for immediate
                  assistance. Proactive monitoring and maintenance for optimal
                  system performance. Comprehensive knowledge base for self-help
                  and quick resolutions.
                </p>
              </div>
            </div>
          </div>
         
          <div className="row">
            <div
              className="col-xl-6 col-md-6 d-flex align-items-stretch mb-3"
              data-aos="zoom-in"
              data-aos-delay="100"
            >
              <div className="icon-box">
                <div className="icon">
                  <i className="bx bxl-dribbble"></i>
                </div>
                <h4>
                  <a href="">Implementation</a>
                </h4>
                <p>
                  Seamless implementation of robust IT solutions tailored to
                  your business. Expert project management to ensure successful
                  system implementation. Customized implementation plans aligned
                  with your specific requirements. Efficient coordination of
                  resources and timelines for smooth deployment. Thorough
                  testing and quality assurance to guarantee optimal
                  performance. Minimizing disruption and downtime during
                  implementation process. Seamless integration with existing
                  systems and workflows.
                </p>
              </div>
            </div>

            <div
              className="col-xl-6 col-md-6 d-flex align-items-stretch mb-3"
              data-aos="zoom-in"
              data-aos-delay="100"
            >
              <div className="icon-box">
                <div className="icon">
                  <i className="bx bxl-dribbble"></i>
                </div>
                <h4>
                  <a href="">Chat Support</a>
                </h4>
                <p>
                  Real-time chat support for immediate assistance. Responsive
                  and knowledgeable chat agents available 24/7. Quick resolution
                  of issues through live chat interactions. Personalized support
                  tailored to your specific needs. Instant answers to your
                  queries and technical problems. Efficient troubleshooting and
                  problem-solving via chat. Prompt guidance on product usage and
                  functionality. Seamless escalation to higher-level support
                  when needed.
                </p>
              </div>
            </div>
          </div>
        </div>
      </section>
    </>
  );
};

export default Services;
