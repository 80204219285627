import React, { useState } from "react";
import { Link } from "react-router-dom";
const Navbar = () => {
  const [isMobileNavOpen, setIsMobileNavOpen] = useState(false);

  const handleMobileNavToggle = () => {
    setIsMobileNavOpen(!isMobileNavOpen);
  };

  const handleLinkClick = () => {
    setIsMobileNavOpen(false);
  };
  return (
    <>
      <header id="header" className="fixed-top">
        <div className="container d-flex align-items-center">
          
          <h1 className="logo me-auto">
          <a href="index.html" className="logo me-auto"><img src="assets/img/apple-touch-icon.png" alt="" className="img-fluid" /></a>

            <a href="/">MultiSolsZone</a>
          </h1>
         
          <nav
            id="navbar"
            className={`navbar ${isMobileNavOpen ? "navbar-mobile" : ""}`}
          >
            <ul>
              <li>
                <Link className="nav-link scrollto active" to="/" onClick={handleLinkClick}>
                  Home
                </Link>
              </li>
              <li>
                <Link className="nav-link scrollto" to="/about" onClick={handleLinkClick}>
                  About
                </Link>
              </li>
              <li>
                <Link className="nav-link scrollto" to="/services" onClick={handleLinkClick}>
                  Services
                </Link>
              </li>
              <li>
                <Link className="nav-link scrollto" to="/portfolio" onClick={handleLinkClick}>
                  Portfolio
                </Link>
              </li>
              <li>
                <Link className="getstarted scrollto" to="/contact" onClick={handleLinkClick}>
                  Contact
                </Link>
              </li>
            </ul>
            <i
              className={`bi bi-list mobile-nav-toggle ${
                isMobileNavOpen ? "bi-x" : ""
              }`}
              onClick={handleMobileNavToggle}
            ></i>
          </nav>
        </div>
      </header>
    </>
  );
};

export default Navbar;
