import React from "react";

const About = () => {
  return (
    <>
      <section id="about" className="about">
        <div className="container" data-aos="fade-up">
          <div className="section-title">
            <h2>About Us</h2>
          </div>
          <div className="row content">
            <div className="col-lg-12">
              <h3>Established: June, 2023</h3>
            </div>
          </div>
          <div className="row content">
            <div className="col-lg-12">
              Defines our IT company's commitment to driving innovation,
              delivering exceptional service, and enabling our clients to
              achieve excellence. We foster a culture that encourages creative
              thinking and leverage emerging technologies to provide
              cutting-edge solutions. Our team of experts collaborates closely
              with clients, understanding their goals and challenges to deliver
              tailored IT services. We continuously enhance our skills and stay
              at the forefront of industry advancements. With our motto guiding
              us, we aim to be a trusted partner, empowering our clients to
              innovate and excel in the fast-paced digital world.
            </div>
          </div>

         
        </div>
      </section>
    </>
  );
};

export default About;
