import "./App.css";
import { BrowserRouter as Router, Route, Routes } from "react-router-dom";
import Body from "./components/Body";
import Footer from "./components/Footer";
import ImageSlider from "./components/ImageSlider";
import Navbar from "./components/Navbar";
import About from "./components/About";
import Contact from "./components/Contact";
import Services from "./components/Services";
import Portfolio from "./components/Portfolio"
import Join from "./components/Join"
import DisplayContact from "./components/DisplayContact";
// import "bootstrap/dist/css/bootstrap.css";


function App() {
  return (
    <>
      <Router>
        <Routes>
          <Route
            exact
            path="/"
            element={
              <>
                <Navbar />
                <ImageSlider />
                <Body />
                <Footer />
              </>
            }
          />

          <Route
            exact
            path="/about"
            element={
              <>
                <Navbar />
                <About />
                <Footer />
              </>
            }
          />

          <Route
            exact
            path="/contact"
            element={
              <>
                <Navbar />
                <Contact />
                <Footer />
              </>
            }
          />

          <Route
            exact
            path="/displaycontact"
            element={
              <>
                <Navbar />
                <DisplayContact />
                <Footer />
              </>
            }
          />
          <Route
            exact
            path="/services"
            element={
              <>
                <Navbar />
                <Services />
                <Footer />
              </>
            }
          />

          <Route
            exact
            path="/portfolio"
            element={
              <>
                <Navbar />
                <Portfolio />
                <Footer />
              </>
            }
          />

<Route
            exact
            path="/join"
            element={
              <>
                <Join />
                              </>
            }
          />

        </Routes>
      </Router>
    </>
  );
}

export default App;
