import React from "react";

const Body = () => {
  return (
    <>
    <section id="clients" className="clients section-bg">
      <div className="container">

        <div className="row" data-aos="zoom-in">

          <div className="col-lg-2 col-md-4 col-6 d-flex align-items-center justify-content-center">
            <img src="assets/img/clients/client-1.png" className="img-fluid" alt="" />
          </div>

          <div className="col-lg-2 col-md-4 col-6 d-flex align-items-center justify-content-center">
            <img src="assets/img/clients/client-2.png" className="img-fluid" alt="" />
          </div>

          <div className="col-lg-2 col-md-4 col-6 d-flex align-items-center justify-content-center">
            <img src="assets/img/clients/client-3.png" className="img-fluid" alt="" />
          </div>

          <div className="col-lg-2 col-md-4 col-6 d-flex align-items-center justify-content-center">
            <img src="assets/img/clients/client-4.png" className="img-fluid" alt="" />
          </div>

          <div className="col-lg-2 col-md-4 col-6 d-flex align-items-center justify-content-center">
            <img src="assets/img/clients/client-5.png" className="img-fluid" alt="" />
          </div>

          <div className="col-lg-2 col-md-4 col-6 d-flex align-items-center justify-content-center">
            <img src="assets/img/clients/client-6.png" className="img-fluid" alt="" />
          </div>

          <div className="col-lg-2 col-md-4 col-6 d-flex align-items-center justify-content-center">
            <img src="assets/img/clients/client-7.png" className="img-fluid" alt="" />
          </div>

          <div className="col-lg-2 col-md-4 col-6 d-flex align-items-center justify-content-center">
            <img src="assets/img/clients/client-8.png" className="img-fluid" alt="" />
          </div>

        </div>

      </div>
    </section>
      <section id="about" className="about">
        <div className="container" data-aos="fade-up">
          <div className="section-title">
            <h2>Our Moto</h2>
          </div>

          <div className="row content">
            <div className="col-lg-12">
              <p>
                Our motto,
                <strong>"Empowering Innovation, Enabling Excellence," </strong>
                defines our IT company's commitment to driving innovation,
                delivering exceptional service, and enabling our clients to
                achieve excellence. We foster a culture that encourages creative
                thinking and leverage emerging technologies to provide
                cutting-edge solutions. Our team of experts collaborates closely
                with clients, understanding their goals and challenges to
                deliver tailored IT services. We continuously enhance our skills
                and stay at the forefront of industry advancements. With our
                motto guiding us, we aim to be a trusted partner, empowering our
                clients to innovate and excel in the fast-paced digital world.
              </p>
            </div>
          </div>
        </div>
      </section>
      <section id="services" className="services section-bg">
      <div className="container" data-aos="fade-up">

        <div className="section-title">
          <h2>Services</h2>
        </div>

        <div className="row">
          <div className="col-xl-3 col-md-6 d-flex align-items-stretch" data-aos="zoom-in" data-aos-delay="100">
            <div className="icon-box">
              <div className="icon"><i className="bx bx-mobile"></i></div>
              <h4><a href="//">Mobile Development</a></h4>
              <p>"Creating innovative and user-friendly mobile applications for seamless experiences."</p>
            </div>
          </div>

          <div className="col-xl-3 col-md-6 d-flex align-items-stretch mt-4 mt-md-0" data-aos="zoom-in" data-aos-delay="200">
            <div className="icon-box">
              <div className="icon"><i className="bx bx-file"></i></div>
              <h4><a href="//">Web Development</a></h4>
              <p>"Crafting responsive and dynamic websites for modern digital experiences."</p>
            </div>
          </div>

          <div className="col-xl-3 col-md-6 d-flex align-items-stretch mt-4 mt-xl-0" data-aos="zoom-in" data-aos-delay="300">
            <div className="icon-box">
              <div className="icon"><i className="bx bx-tachometer"></i></div>
              <h4><a href="//">Digital Commerce</a></h4>
              <p>"Transforming businesses with seamless online buying and selling experiences."</p>
            </div>
          </div>

          <div className="col-xl-3 col-md-6 d-flex align-items-stretch mt-4 mt-xl-0" data-aos="zoom-in" data-aos-delay="400">
            <div className="icon-box">
              <div className="icon"><i className="bx bx-layer"></i></div>
              <h4><a href="//">Security</a></h4>
              <p>"Ensuring robust protection through secure software development and vigilant safeguards."</p>
            </div>
          </div>

        </div>
        <br />
        <div className="row">
          <div className="col-xl-3 col-md-6 d-flex align-items-stretch" data-aos="zoom-in" data-aos-delay="100">
            <div className="icon-box">
              <div className="icon"><i className="bx bxl-dribbble"></i></div>
              <h4><a href="//">Application Integeration</a></h4>
              <p>"Seamlessly connecting applications for enhanced productivity and data flow efficiency."</p>
            </div>
          </div>

          <div className="col-xl-3 col-md-6 d-flex align-items-stretch mt-4 mt-md-0" data-aos="zoom-in" data-aos-delay="200">
            <div className="icon-box">
              <div className="icon"><i className="bx bx-file"></i></div>
              <h4><a href="//">Module Integeration</a></h4>
              <p>"Integrating modules for efficient functionality and streamlined software performance."</p>
            </div>
          </div>

          <div className="col-xl-3 col-md-6 d-flex align-items-stretch mt-4 mt-xl-0" data-aos="zoom-in" data-aos-delay="300">
            <div className="icon-box">
              <div className="icon"><i className="bx bx-tachometer"></i></div>
              <h4><a href="//">QA</a></h4>
              <p>"Ensuring software quality through rigorous testing and meticulous quality assurance."</p>
            </div>
          </div>

          <div className="col-xl-3 col-md-6 d-flex align-items-stretch mt-4 mt-xl-0" data-aos="zoom-in" data-aos-delay="400">
            <div className="icon-box">
              <div className="icon"><i className="bx bx-layer"></i></div>
              <h4><a href="//">UI/UX</a></h4>
              <p>"Creating captivating user experiences with intuitive and visually appealing interfaces."</p>
            </div>
          </div>

        </div>
      </div>
    </section>

    {/* <section id="portfolio" className="portfolio">
      <div className="container" data-aos="fade-up">

        <div className="section-title">
          <h2>Portfolio</h2>
        </div>

        <ul id="portfolio-flters" className="d-flex justify-content-center" data-aos="fade-up" data-aos-delay="100">
          <li data-filter="*" className="filter-active">All</li>
          <li data-filter=".filter-app">App</li>
          <li data-filter=".filter-card">Card</li>
          <li data-filter=".filter-web">Web</li>
        </ul>

        <div className="row portfolio-container" data-aos="fade-up" data-aos-delay="200">

          <div className="col-lg-4 col-md-6 portfolio-item filter-app">
            <div className="portfolio-img"><img src="assets/img/portfolio/portfolio-1.jpg" className="img-fluid" alt="" /></div>
            <div className="portfolio-info">
              <h4>App 1</h4>
              <p>App</p>
              <a href="/assets/img/portfolio/portfolio-1.jpg" data-gallery="portfolioGallery" className="portfolio-lightbox preview-link" title="App 1"><i className="bx bx-plus"></i></a>
              <a href="/portfolio-details.html" className="details-link" title="More Details"><i className="bx bx-link"></i></a>
            </div>
          </div>

          <div className="col-lg-4 col-md-6 portfolio-item filter-web">
            <div className="portfolio-img"><img src="assets/img/portfolio/portfolio-2.jpg" className="img-fluid"alt="" /></div>
            <div className="portfolio-info">
              <h4>Web 3</h4>
              <p>Web</p>
              <a href="/assets/img/portfolio/portfolio-2.jpg" data-gallery="portfolioGallery" className="portfolio-lightbox preview-link" title="Web 3"><i className="bx bx-plus"></i></a>
              <a href="/portfolio-details.html" className="details-link" title="More Details"><i className="bx bx-link"></i></a>
            </div>
          </div>

          <div className="col-lg-4 col-md-6 portfolio-item filter-app">
            <div className="portfolio-img"><img src="assets/img/portfolio/portfolio-3.jpg" className="img-fluid"alt="" /></div>
            <div className="portfolio-info">
              <h4>App 2</h4>
              <p>App</p>
              <a href="/assets/img/portfolio/portfolio-3.jpg" data-gallery="portfolioGallery" className="portfolio-lightbox preview-link" title="App 2"><i className="bx bx-plus"></i></a>
              <a href="/portfolio-details.html" className="details-link" title="More Details"><i className="bx bx-link"></i></a>
            </div>
          </div>

          <div className="col-lg-4 col-md-6 portfolio-item filter-card">
            <div className="portfolio-img"><img src="assets/img/portfolio/portfolio-4.jpg" className="img-fluid"alt="" /></div>
            <div className="portfolio-info">
              <h4>Card 2</h4>
              <p>Card</p>
              <a href="/assets/img/portfolio/portfolio-4.jpg" data-gallery="portfolioGallery" className="portfolio-lightbox preview-link" title="Card 2"><i className="bx bx-plus"></i></a>
              <a href="/portfolio-details.html" className="details-link" title="More Details"><i className="bx bx-link"></i></a>
            </div>
          </div>

          <div className="col-lg-4 col-md-6 portfolio-item filter-web">
            <div className="portfolio-img"><img src="assets/img/portfolio/portfolio-5.jpg" className="img-fluid"alt="" /></div>
            <div className="portfolio-info">
              <h4>Web 2</h4>
              <p>Web</p>
              <a href="/assets/img/portfolio/portfolio-5.jpg" data-gallery="portfolioGallery" className="portfolio-lightbox preview-link" title="Web 2"><i className="bx bx-plus"></i></a>
              <a href="/portfolio-details.html" className="details-link" title="More Details"><i className="bx bx-link"></i></a>
            </div>
          </div>

          <div className="col-lg-4 col-md-6 portfolio-item filter-app">
            <div className="portfolio-img"><img src="assets/img/portfolio/portfolio-6.jpg" className="img-fluid"alt="" /></div>
            <div className="portfolio-info">
              <h4>App 3</h4>
              <p>App</p>
              <a href="/assets/img/portfolio/portfolio-6.jpg" data-gallery="portfolioGallery" className="portfolio-lightbox preview-link" title="App 3"><i className="bx bx-plus"></i></a>
              <a href="/portfolio-details.html" className="details-link" title="More Details"><i className="bx bx-link"></i></a>
            </div>
          </div>

          <div className="col-lg-4 col-md-6 portfolio-item filter-card">
            <div className="portfolio-img"><img src="assets/img/portfolio/portfolio-7.jpg" className="img-fluid"alt="" /></div>
            <div className="portfolio-info">
              <h4>Card 1</h4>
              <p>Card</p>
              <a href="/assets/img/portfolio/portfolio-7.jpg" data-gallery="portfolioGallery" className="portfolio-lightbox preview-link" title="Card 1"><i className="bx bx-plus"></i></a>
              <a href="/portfolio-details.html" className="details-link" title="More Details"><i className="bx bx-link"></i></a>
            </div>
          </div>

          <div className="col-lg-4 col-md-6 portfolio-item filter-card">
            <div className="portfolio-img"><img src="assets/img/portfolio/portfolio-8.jpg" className="img-fluid"alt="" /></div>
            <div className="portfolio-info">
              <h4>Card 3</h4>
              <p>Card</p>
              <a href="/assets/img/portfolio/portfolio-8.jpg" data-gallery="portfolioGallery" className="portfolio-lightbox preview-link" title="Card 3"><i className="bx bx-plus"></i></a>
              <a href="/portfolio-details.html" className="details-link" title="More Details"><i className="bx bx-link"></i></a>
            </div>
          </div>

          <div className="col-lg-4 col-md-6 portfolio-item filter-web">
            <div className="portfolio-img"><img src="assets/img/portfolio/portfolio-9.jpg" className="img-fluid"alt="" /></div>
            <div className="portfolio-info">
              <h4>Web 3</h4>
              <p>Web</p>
              <a href="/assets/img/portfolio/portfolio-9.jpg" data-gallery="portfolioGallery" className="portfolio-lightbox preview-link" title="Web 3"><i className="bx bx-plus"></i></a>
              <a href="/portfolio-details.html" className="details-link" title="More Details"><i className="bx bx-link"></i></a>
            </div>
          </div>

        </div>

      </div>
    </section> */}

<section id="team" className="team section-bg">
      <div className="container" data-aos="fade-up">

        <div className="section-title">
          <h2>Team</h2>
        </div>

        <div className="row">

          <div className="col-lg-4" data-aos="zoom-in" data-aos-delay="100">
            <div className="member d-flex align-items-start">
              <div className="pic"><img src="assets/img/team/team-1.jpeg" className="img-fluid" alt="" /></div>
              <div className="member-info">
                <h4>Umer Arshad</h4> <br />
                <h3>Founder</h3>
                <div className="social">
                  <a href="/"><i className="ri-twitter-fill"></i></a>
                  <a href="https://www.facebook.com/umer.arshad.1276?mibextid=ZbWKwL"><i className="ri-facebook-fill"></i></a>
                  <a href="/"><i className="ri-instagram-fill"></i></a>
                  <a href="https://www.linkedin.com/in/umer-arshad-a7789021b/"> <i className="ri-linkedin-box-fill"></i> </a>
                </div>
                {/* <span>Founder</span> Skills: Web Developer */}
                <div className="skills">Web Developer</div>
               
              </div>
            </div>
          </div>

          <div className="col-lg-4" data-aos="zoom-in" data-aos-delay="100">
            <div className="member d-flex align-items-start">
              <div className="pic"><img src="assets/img/team/team-1.jpeg" className="img-fluid" alt="" /></div>
              <div className="member-info">
                <h4>Abdul Moiz</h4><br />
                <h3>Co-Founder</h3>
                <div className="social">
                  <a href="/"><i className="ri-twitter-fill"></i></a>
                  <a href="/"><i className="ri-facebook-fill"></i></a>
                  <a href="/"><i className="ri-instagram-fill"></i></a>
                  <a href="/"> <i className="ri-linkedin-box-fill"></i> </a>
                </div>
                {/* <span>Co-Founder</span> Skills: Full Stack Developer */}
                <div className="skills">Full Stack Developer</div>
                
              </div>
            </div>
          </div>

          <div className="col-lg-4" data-aos="zoom-in" data-aos-delay="100">
            <div className="member d-flex align-items-start">
              <div className="pic"><img src="assets/img/team/team-2.png" className="img-fluid" alt="" /></div>
              <div className="member-info">
                <h4>Muhammad Adeel Baig</h4>
                <h3>Co-Founder</h3>
                <div className="social">
                  <a href="/"><i className="ri-twitter-fill"></i></a>
                  <a href="/"><i className="ri-facebook-fill"></i></a>
                  <a href="/"><i className="ri-instagram-fill"></i></a>
                  <a href="/"> <i className="ri-linkedin-box-fill"></i> </a>
                </div>
                {/* <span>Co-Founder</span> Skills: Full Stack Developer */}
                <div className="skills">Full Stack Developer <br /> UI/UX Designer</div>
               
              </div>
            </div>
          </div>
        </div>

      </div>
    </section>
    </>
  );
};

export default Body;
