import React , {useRef} from 'react'
import './join.css'
import emailjs from '@emailjs/browser'
const Join = () => {
    const form = useRef()
    const sendEmail = (e) => {
        e.preventDefault();
    
        emailjs.sendForm('service_aw7x54i', 'template_08i1brs', form.current, 'AEWomtgK5CNFg5w3a')
          .then((result) => {
              console.log(result.text);
          }, (error) => {
              console.log(error.text);
          });
      };
  return (
    <div >
       <div className='right-j'>
        <form ref={form} className='email-container' onSubmit={sendEmail}>
            <input type='email' name='user_email' placeholder='Enter your Email'></input>
            <button className='btn btn-join'>Send</button>
        </form>
       </div>
    </div>
  )
}

export default Join